import { useTranslation } from "react-i18next";

const Table = ({ organizations }) => {
  const { t } = useTranslation();

  return (
    <div className="overflow-x-auto flow-root min-w-full align-middle overflow-hidden rounded-lg border pb-24">
      <table className="min-w-full">
        <thead className="border-b">
          <tr>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-900 uppercase">
              {t("promo_code.organization")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-900 uppercase">
              {t("promo_code.plan")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-900 uppercase">
              {t("promo_code.referral_start_at")}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {organizations &&
            organizations.map((organization) => (
              <tr key={organization._id}>
                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                  <div className="flex items-center">
                    <img src={organization.logo} alt="Avatar" className="h-8 w-8 rounded-full" />
                    <span className="ml-3">{organization.name}</span>
                  </div>
                </td>
                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{organization.plan}</td>
                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{organization.referralStartAt}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
