import api from "@services/api";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { Mixpanel } from "@services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { setOrganization } from "@redux/auth/actions";
import { copyToClipboard } from "@utils";
import { ENVIRONMENT } from "@config";
import { FaLink, FaArrowRight } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import Modal from "@components/utils/Modal";
import DirectReferralTable from "./components/direct";
import IndirectReferralsTable from "./components/indirect";

const List = () => {
  const organization = useSelector((state) => state.Auth.organization);
  const user = useSelector((state) => state.Auth.user);
  const [referrals, setReferrals] = useState([]);
  const [indirectReferrals, setIndirectReferrals] = useState([]);
  const [extendedReferals, setExtentedReferrals] = useState([]);
  const [open, setOpen] = useState(!organization.referralCGVAcceptedAt);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const redirectUrl = ENVIRONMENT === "development" ? "http://localhost:8082" : "https://app.finotor.com";

  const copyAffiliateLink = () => {
    copyToClipboard(`${redirectUrl}/auth/signup?referrerCode=${organization.referralCode}&lang=${user.language.toLowerCase()}`);
    toast.success(t("promo_code.copy_to_clipboard"));
    Mixpanel.track(MIXPANEL_EVENTS.btn_copy_referral_link);
  };

  const getReferrals = async () => {
    try {
      const { data } = await api.post(`/organization/referrals`, {
        OrganizationId: organization._id,
      });

      setReferrals(data.referrals);
      setIndirectReferrals(data.indirectReferrals);
      setExtentedReferrals(data.extendedReferals);
    } catch (error) {
      toast.error(t("promo_code.error_referrals"));
      console.error(error);
    }
  };

  const acceptReferralCGV = async () => {
    if (organization.referralCGVAcceptedAt) {
      setOpen(false);
      return;
    }
    try {
      const { ok, data } = await api.put(`/organization/${organization._id}`, { referralCGVAcceptedAt: new Date() });
      if (!ok) {
        toast.error(t("promo_code.error_accept_cgv"));
        return;
      }
      dispatch(setOrganization(data));
      toast.success(t("promo_code.success_accept_cgv"));
    } catch (error) {
      toast.error(t("promo_code.error_accept_cgv"));
      console.error(error);
    } finally {
      setOpen(false);
    }
  };

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_referral_page);
    getReferrals();
    setOpen(!organization.referralCGVAcceptedAt);
  }, [organization]);

  if (!organization.referralCGVAcceptedAt) {
    return (
      <div className="mx-auto">
        <div className="py-6 px-6">
          <div className="flex flex-col bg-white rounded-xl p-6">
            <div className="flex justify-between items-start">
              <div>
                <h1 className="text-2xl font-bold">{t("promo_code.title")}</h1>
                <p className="mt-2 text-slate-600">{t("promo_code.description")}</p>
              </div>
              <div className="flex flex-col gap-2">
                <button className="btn-secondary w-fit mt-4" onClick={() => setOpen(true)}>
                  {t("promo_code.button_open_cgv")}
                </button>
              </div>
              <ReferralCGVModal open={open} setOpen={setOpen} onClick={acceptReferralCGV} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto">
      <div className="py-6 px-6">
        <div className="flex flex-col bg-white rounded-xl p-6">
          <div className="flex justify-between items-start">
            <div>
              <h1 className="text-2xl font-bold">{t("promo_code.title")}</h1>
              <p className="mt-2 text-slate-600">{t("promo_code.description")}</p>
            </div>
            <div className="flex flex-col gap-2">
              <button className="btn-primary focus:bg-blue float-right mb-2" onClick={() => copyAffiliateLink()}>
                <FaLink className="mr-2" />
                {t("promo_code.share_link")}
              </button>
            </div>
          </div>
          <div className="mt-8 flex flex-col space-y-3">
            <span className="text-lg text-slate-600">{t("promo_code.level_one")}</span>
            <DirectReferralTable organizations={referrals} />
          </div>
          <div className="mt-2 flex flex-col space-y-3">
            <span className="mt-2 text-lg text-slate-600">{t("promo_code.level_two")}</span>
            <IndirectReferralsTable organizations={indirectReferrals} />
          </div>
          <div className="mt-2 flex flex-col space-y-3">
            <span className="mt-2 text-lg text-slate-600">{t("promo_code.level_three")}</span>
            <IndirectReferralsTable organizations={extendedReferals} />
          </div>
          <div className="mt-8 flex-row flex gap-1 w-full items-center justify-start hover:underline hover:cursor-pointer" onClick={() => setOpen(true)}>
            <span className="">{t("promo_code.read_cgv")}</span>
            <FaArrowRight size={12} />
          </div>
        </div>
        <ReferralCGVModal open={open} setOpen={setOpen} onClick={acceptReferralCGV} />
      </div>
    </div>
  );
};

const ReferralCGVModal = ({ open, setOpen, onClick }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={open} onClose={() => setOpen(!open)} className="flex w-[75vw] max-h-full">
      <div className="w-full h-full px-8 py-4 bg-white rounded-xl">
        <div className="flex items-center gap-3 mb-4">
          <img src="/favicon.png" alt="finotor" className="w-10 h-10 aspect-square mx-auto" />
          <h2 className="text-xl font-bold w-full">{t("promo_code.cgv")}</h2>
        </div>
        <p className="text-slate-600 mb-4">{t("promo_code.cgv_description")}</p>
        <div className="overflow-y-auto max-h-96">
          <div className="space-y-6">
            <h3 className="text-xl font-semibold text-gray-900">{t("promo_code.cgv.pre_title")}</h3>
            <p className="text-gray-700 leading-relaxed">{t("promo_code.cgv.pre_paragraph_1")}</p>
            <p className="text-gray-700 leading-relaxed">{t("promo_code.cgv.pre_paragraph_2")}</p>

            <h3 className="text-xl font-semibold text-gray-900">{t("promo_code.cgv.eligibility_title")}</h3>
            <p className="text-gray-700 leading-relaxed">{t("promo_code.cgv.eligibility_paragraph")}</p>

            <h3 className="text-xl font-semibold text-gray-900">{t("promo_code.cgv.object_title")}</h3>
            <p className="text-gray-700 leading-relaxed">{t("promo_code.cgv.object_paragraph")}</p>

            <h3 className="text-xl font-semibold text-gray-900">{t("promo_code.cgv.compensation_title")}</h3>
            <p className="text-gray-700 leading-relaxed">{t("promo_code.cgv.compensation_paragraph_1")}</p>
            <p className="text-gray-700 leading-relaxed">
              <strong>{t("promo_code.cgv.compensation_paragraph_2_title")}</strong>
              {t("promo_code.cgv.compensation_paragraph_2")}
            </p>
            <p className="text-gray-700 leading-relaxed">
              <strong>{t("promo_code.cgv.compensation_paragraph_3_title")}</strong>
              {t("promo_code.cgv.compensation_paragraph_3")}
            </p>
            <p className="text-gray-700 leading-relaxed">
              <strong>{t("promo_code.cgv.compensation_paragraph_4_title")}</strong>
              {t("promo_code.cgv.compensation_paragraph_4")}
            </p>
            <p className="text-gray-700 leading-relaxed">
              <strong>{t("promo_code.cgv.compensation_paragraph_5_title")}</strong>
              {t("promo_code.cgv.compensation_paragraph_5")}
            </p>
            <p className="text-gray-700 leading-relaxed">{t("promo_code.cgv.compensation_paragraph_6")}</p>

            <h3 className="text-xl font-semibold text-gray-900">{t("promo_code.cgv.obligations_title")}</h3>
            <p className="text-gray-700 leading-relaxed">{t("promo_code.cgv.obligations_paragraph")}</p>

            <h3 className="text-xl font-semibold text-gray-900">{t("promo_code.cgv.terms_update_title")}</h3>
            <p className="text-gray-700 leading-relaxed">{t("promo_code.cgv.terms_update_paragraph")}</p>

            <h3 className="text-xl font-semibold text-gray-900">{t("promo_code.cgv.termination_title")}</h3>
            <p className="text-gray-700 leading-relaxed">{t("promo_code.cgv.termination_paragraph")}</p>

            <h3 className="text-xl font-semibold text-gray-900">{t("promo_code.cgv.intellectual_property_title")}</h3>
            <p className="text-gray-700 leading-relaxed">{t("promo_code.cgv.intellectual_property_paragraph")}</p>

            <h3 className="text-xl font-semibold text-gray-900">{t("promo_code.cgv.liability_title")}</h3>
            <p className="text-gray-700 leading-relaxed">{t("promo_code.cgv.liability_paragraph")}</p>

            <h3 className="text-xl font-semibold text-gray-900">{t("promo_code.cgv.jurisdiction_title")}</h3>
            <p className="text-gray-700 leading-relaxed">{t("promo_code.cgv.jurisdiction_paragraph")}</p>
          </div>
        </div>
        <div className="flex justify-end gap-4 mt-2">
          <button className="btn-primary" onClick={onClick}>
            {t("promo_code.button_confirm_cgv")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default List;
